export default {
  circle: {
    color: "background",
    bg: "text",
    borderRadius: "50%",
    position: `absolute`,
    top: 0,
    right: 0,
    width: `1.5rem`,
    height: `1.5rem`,
    textAlign: `center`,
    lineHeight: `1.5rem`,
    overflow: "hidden",
    textOverflow: "hidden",
  },
}
