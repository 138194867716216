import React from "react"
import { Box, Flex } from "theme-ui"
import Next from "@components/slider/Next"
import Prev from "@components/slider/Prev"

export const colorModes = { dark: `dark`, light: `light` }
export const tokenTypes = { recovery: `recovery`, signup: `signup` }

export const imgSliderProps = {
  arrows: false,
  dots: false,
  fade: true,
}

export const contentSliderProps = {
  arrows: true,
  nextArrow: <Next />,
  prevArrow: <Prev />,
  dots: true,
  inifinite: false,
  vertical: true,
  swipeToSlide: true,
  focusOnSelect: true,
  customPaging: i => (
    <Flex variant="layout.contentSliderRange">
      <Box />
    </Flex>
  ),
}

export const identityUrl = "https://new.dworld.pl"

export const errorMessages = {
  alreadyInUseEmail: "A user with this email address has already been registered",
  emailNotValid: `Podaj pełny adres e-mail (np. jan.kowalski@domena.pl).`,
  invalidPassword: 'invalid_grant: Invalid Password',
  noUserFound: `invalid_grant: No user found with this email`,
  passwordTooShort: `Hasło powinno składać się z co najmniej 8 znaków`,
  required: `Wypełnienie tego pola jest wymagane`,
}

export const errorMessagesPL = {
  alreadyInUseEmail: "Wygląda na to, że masz już u nas konto. Spróbuj zalogować się ponownie.",
  invalidPassword: "Logowanie nie udało się spróbuj ponownie",
  noUserFound: "Logowanie nie udało się spróbuj ponownie",
}