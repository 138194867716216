export default {
  primary: {
    color: "background",
    bg: "primary",
  },
  muted: {
    color: "text",
    bg: "muted",
  },
  fieldError: {
      bg: `transparent`,
      color: `danger`,
      flex: `1 0 100%`,
      fontFamily: `body`,
      fontSize: '13px',
      fontWeight: 400,
      justifyContent: `center`,
      paddingTop: '0.5rem',
  }
}
