import React from "react"
import { IconButton } from "theme-ui"
import ArrowRightIcon from "../../assets/arrowRight.svg"

export default props => (
  <IconButton
    aria-label="Next slide"
    variant="buttons.slide.next"
    {...props}
    onClick={() => props.onClick()}
  >
    <ArrowRightIcon />
  </IconButton>
)
