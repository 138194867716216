export default {
  header: {
    alignItems: "baseline",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-between",
    color: "white",
    position: "absolute",
    zIndex: 1,
    px: [5],
    py: ["2rem", null, "4rem", null],
  },

  main: {
    flex: 1,
    flexDirection: "column",
  },

  form: {
    container: {
      display: `flex`,
      justifyContent: "center",
      flexWrap: `wrap`,
      my: `12rem`,
      width: `540px`,
    },
  },
  // Single event
  event: {
    container: {
      backgroundColor: "rgba(13, 21, 55, 0.7)",
      minHeight: "960px",
      position: "relative",
    },
    prelegents: {
      mx: [3, 5, 6, 8],
      flex: "1",
      mt: "116px",
      position: "relative",
      flexWrap: "wrap",
      h4: {
        flex: "1 0 100%",
        fontFamily: "body",
        fontSize: "26px",
        lineHeight: "39px",
        mb: "60px",
      },
      "> div": {
        flex: "1 0 100%",
      },
    },
    content: {
      mx: [3, 5, 6, 8],
      flex: "1",
      mt: "116px",
      position: "relative",
    },
    links: {
      flex: `1 0 35%`,
      borderLeft: `1px solid #cacaca`,
      pl: `128px`,
      flexWrap: "wrap",
      h4: {
        fontFamily: "body",
        fontSize: "26px",
        lineHeight: "39px",
      },
    },
    header: {
      color: "black",
      height: "276px",
      mt: "438px",
      mx: [3, 5, 6, 8],
      zIndex: 3,
      flexWrap: "wrap",
      h1: {
        color: "white",
        ml: `80px`,
        pb: [4],
        pt: 0,
        "+p": {
          ml: `80px`,
        },
      },
    },
    details: {
      flex: `1 0 65%`,
      flexWrap: "wrap",
      alignContent: "flex-start",
      "> div": {
        alignItems: "center",
        flex: `1 0 100%`,
        mt: "30px",
      },
      h4: {
        flex: `1 0 100%`,
        fontFamily: "body",
        fontSize: "26px",
        lineHeight: "39px",
      },
      h5: {
        flex: `1 0 100%`,
        fontSize: "28px",
        lineHeight: "32px",
        mt: "30px",
        mb: "16px",
      },
      p: {
        color: "#0D1537",
        fontFamily: `heading`,
        fontSize: `20px`,
        lineHeight: "24px",
        pl: "30px",
        letterSpacing: `0px`,
      },
    },
  },

  // Calendar
  calendar: {
    days: {
      flex: `1 0 100%`,
      display: `inline-grid`,
      gridTemplateColumns: `repeat(7, 1fr)`,
      gridTemplateRows: `auto`,
      justifyItems: `center`,
      gridColumnGap: [0, `1rem`, `2rem`],
    },
    day: {
      color: `#0D1537`,
      fontFamily: `heading`,
      fontSize: [`1rem`, `20px`],
      justifyContent: `center`,
      lineHeight: [`2rem`, `3.625rem`],
      width: [`2rem`, `3.625rem`],
    },
    weekdays: {
      flex: `1 0 100%`,
      display: `inline-grid`,
      gridTemplateColumns: `repeat(7, 1fr)`,
      gridTemplateRows: `auto`,
      justifyContent: `space-around`,
      justifyItems: `center`,
      minWidth: `auto`,
      gridColumnGap: [0, `1rem`, `2rem`],
    },
    weekday: {
      color: `#939393`,
      fontFamily: `heading`,
      fontSize: [`1rem`, `20px`],
      justifyContent: `center`,
      lineHeight: [`2rem`, `3.625rem`],
      width: [`2rem`, `3.625rem`],
      textAlign: `center`,
    },
    container: {
      color: "black",
      flex: "1",
      position: "relative",
      width: "100%",
    },
    content: {
      mx: [`1rem`, null, `7.5rem`],
      flex: "1",
      mt: "0",
      position: "relative",
      flexWrap: "wrap",
    },
    widget: {
      flex: [`1 0 100%`, null, `1 0 50rem`],
      flexWrap: `wrap`,
      background: `#FFFFFF 0% 0% no-repeat padding-box`,
      p: [`1rem`, null, `6rem`],
    },
    heading: {
      borderBottom: `2px solid #FBB03B`,
      flex: `1 0 100%`,
      mb: `1rem`,
      pb: `1rem`,
      h4: {
        alignItems: `center`,
        display: `flex`,
      },
    },
    eventData: {
      flex: `1 0 35%`,
      flexDirection: 'column'
    },
    header: {
      color: "black",
      height: `auto`,
      minHeight: `18rem`,
      mt: "270px",
      mx: [3, 5, 6, 8],
      zIndex: 3,
      h1: {
        ml: `80px`,
        pb: [4],
        pt: 0,
        "+p": {
          ml: `80px`,
        },
      },
    },
  },

  /// FAQ
  faq: {
    bubble: {
      width: `121px`,
      height: `235px`,
      textAlign: `left`,
      fontFamily: "heading",
      fontSize: "200px",
      fontWeight: "bold",
      lineHeight: "166px",
      letterSpacing: `0px`,
      color: `#FBB03B`,
    },
    container: {
      backgroundColor: "white",
      color: "black",
      flex: "1",
      position: "relative",
      width: "100%",
    },
    content: {
      mx: `120px`,
      flex: "1",
      mt: "0",
      position: "relative",
      flexWrap: "wrap",
    },
    section: {
      flex: `1 0 100%`,
      fontFamily: `heading`,
      fontWeight: 600,
      fontSize: `20px`,
      letterSpacing: `0px`,
      color: `#606060`,
      flexWrap: "wrap",
    },
    header: {
      color: "black",
      height: "276px",
      mt: "270px",
      mx: [3, 5, 6, 8],
      zIndex: 3,
      h1: {
        ml: `80px`,
        pb: [4],
        pt: 0,
        "+p": {
          ml: `80px`,
        },
      },
    },
  },
  /// Contact
  contact: {
    bubble: {
      mt: [3],
    },
    container: {
      backgroundColor: "#0D1537",
      flex: "1",
      position: "relative",

      width: "100%",
    },
    content: {
      mx: [3, 5, 6, 8],
      flex: "1",
      flexWrap: `wrap`,
      mt: "116px",
      position: "relative",
    },
    form: {
      flex: [`1 0 100%`, null, `1 0 65%`],
      justifyContent: [`center`, null, `left`],
      pr: [0, null, 5],
      h4: {
        fontFamily: "body",
        fontSize: "26px",
        lineHeight: "39px",
        color: "text",
      },
      "> form": {
        width: `100%`,
      },
    },
    data: {
      borderLeft: [`none`, null, `1px solid #FBB03B`],
      flex: [`1 0 100%`, null, `1 0 35%`],
      justifyContent: [`center`, null, `left`],
      flexWrap: "wrap",
      pl: [0, null, 5],
      socialButtons: {
        color: `text`,
        fontFamily: `heading`,
        fontSize: `20px`,
        lineHeight: `29px`,
        flex: `1 0 100%`,
        mb: "30px",
        mt: "80px",
      },
      "> div": {
        alignItems: "center",
        flex: `1 0 100%`,
        mt: "30px",
      },
      h4: {
        flex: `1 0 100%`,
        fontFamily: "body",
        fontSize: "26px",
        lineHeight: "39px",
      },
      h5: {
        flex: `1 0 100%`,
        fontSize: "28px",
        lineHeight: "32px",
        mt: "30px",
        mb: "16px",
      },
      p: {
        color: "#0D1537",
        fontFamily: `heading`,
        fontSize: `20px`,
        lineHeight: "24px",
        pl: "30px",
        letterSpacing: `0px`,
      },
    },
    header: {
      color: "white",
      mt: "270px",
      mx: [3, 5, 6, 8],
      zIndex: 3,
      h1: {
        ml: `80px`,
        pb: [4],
        pt: 0,
        "+p": {
          ml: `80px`,
        },
      },
    },
    heading: {
      p: 0,
    },
  },

  /// Events
  events: {
    container: {
      backgroundColor: "#FBB03B",
      flex: "1 0 960px",
      position: "relative",
      width: "100%",
    },
    item: {
      backgroundColor: "rgba(13, 21, 55, 0.7)",
      position: "relative",
      flex: ["1 0 100%"],
      flexWrap: "wrap",
      px: "76px",
      pb: "54px",
      pt: "104px",
      "&::before": {
        content: '" "',
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
      },
      "> h4": {
        flex: "1 0 100%",
        color: "white",
        mb: [2],
      },
      "> p": {
        flex: "1 0 100%",
        color: "white",
        mb: [2],
      },
    },
    contentSlide: {
      color: "background",
      flexDirection: "column",
      height: "508px",
      mt: "40px",
      mb: "40px",
      paddingLeft: [6, null, null, "274px"],
      paddingRight: [6, null, null, "500px"],
      link: {
        alignItems: "center",
        color: "text",
        fontFamily: "body",
        fontWright: "heading",
        fontSize: "15px",
        letterSpacing: "1.2px",
        textTransform: "uppercase",
        textDecorationStyle: "none",
        "> button": {
          marginRight: "24px",
          marginLeft: 0,
          "&:before": {
            bg: "background",
          },
        },
      },
    },
  },

  homeImgSlide: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "960px",
  },

  contentSliderContainer: {
    zIndex: 3,
    height: "508px",
    marginTop: "226px",
  },
  contentSliderRange: {
    padding: "0 4px",
    flex: "1",
    height: "100%",
    "> div": {
      backgroundColor: "white",
      width: "3px",
    },
  },
  flexColumn: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
  },
  footer: {
    borderTopColor: "muted",
    borderTopStyle: "solid",
    borderTopWidth: "1px",
    color: "secondary",
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "24px",
    marginBottom: "46px",
    pt: "33px",
    textAlign: "center",
    textTransform: "uppercase",
    column: {
      flexDirection: "column",
      marginBottom: "48px",
      marginTop: "88px",
      flex: [`0 33%`, null, `0 17%`],
      "> ul": {
        listStyleType: "none",
        p: 0,
        m: 0,
        "> li": {
          p: 0,
          m: 0,
        },
      },
    },
    text: {
      color: "secondary",
      flex: 1,
      fontFamily: "body",
      fontSize: "13px",
      letterSpacing: "0px",
      lineHeight: "1.625rem",
      ml: "12px",
      pt: "32px",
      pb: "26px",
      width: "380px",
    },
  },
  footerContainer: {
    flex: 1,
    flexWrap: "wrap",
    mx: [3, 5, 6, 8],
  },
  footerSocialLinks: {
    alignItems: [`center`, null, `flex-start`],
    flex: [`1 0 100%`, null, 1],
    flexDirection: "column",
    marginBottom: "54px",
    marginTop: "94px",
  },
  homeContainer: {
    backgroundColor: "#0D1537",
    flex: "1 0 960px",
    position: "relative",
    width: "100%",
    "&:before": {
      content: '" "',
      position: "absolute",
      bottom: 0,
      height: "156px",
      width: "50%",
      background: "white",
      zIndex: 1,
    },
  },

  homeContentSlide: {
    color: "white",
    flexDirection: "column",
    height: "508px",
    mt: "40px",
    mb: "40px",
    paddingLeft: [6, null, null, "274px"],
    paddingRight: [6, null, null, "500px"],
    link: {
      alignItems: "center",
      color: "white",
      fontFamily: "body",
      fontWright: "heading",
      fontSize: "15px",
      letterSpacing: "1.2px",
      textTransform: "uppercase",
      textDecorationStyle: "none",
      "> button": {
        marginRight: "24px",
        marginLeft: 0,
      },
    },
  },
  imgSliderContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },

  mainNavigation: {
    justifyContent: "space-evenly",
    position: `absolute`,
    top: [`8rem`, null, `5rem`],
    zIndex: 2,
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  mediaIcon: {
    height: "46px",
    margin: "25px",
    width: "46px",
  },
  newsletter: {
    fontSize: "29px",
    color: "white",
    fontFamily: "heading",
    lineHeight: "38px",
    letterSpacing: "0px",
  },
  subnav: {
    display: [`block`, null, `flex`],
    flex: 1,
    flexWrap: "wrap",
    mx: [3, 5, 6, 8],
    mt: "136px",
    "> div": {
      flex: [`1 0 100%`, null, `1 0 50%`],
    },
    "> h1": {
      color: "black",
    },
    "> h3": {
      display: "flex",
      flex: "1 0 100%",
      mb: "72px",
    },
    item: {
      backgroundColor: "rgba(13, 21, 55, 0.7)",
      position: "relative",
      flexWrap: "wrap",
      px: "76px",
      pb: "54px",
      pt: "194px",
      width: "100%",
      "&::before": {
        content: '" "',
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
      },
      "> h2": {
        flex: "1 0 100%",
        color: "white",
        mb: [2],
      },
      "&:nth-of-type(1)": {
        borderBottom: "1px solid",
        borderBottomColor: "#5a5a59",
      },
      "&:nth-of-type(2)": {
        borderBottom: "1px solid",
        borderBottomColor: "#5a5a59",
      },
      "&:nth-of-type(even)": {
        justifyContent: "flex-end",
        borderLeft: "1px solid",
        borderLeftColor: "#5a5a59",
        "> h2": {
          textAlign: "right",
        },
      },
    },
  },
}
