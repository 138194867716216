import React from "react"
import { IconButton } from "theme-ui"
import ArrowLeftIcon from "../../assets/arrowLeft.svg"

export default props => (
  <IconButton
    aria-label="Previous slide"
    variant="buttons.slide.prev"
    {...props}
    onClick={() => props.onClick()}
  >
    <ArrowLeftIcon />
  </IconButton>
)
