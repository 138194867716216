import alerts from "@theme/alerts"
import badges from "@theme/badges"
import breakpoints from "@theme/breakpoints"
import buttons from "@theme/buttons"
import colors from "@theme/colors"
import fonts from "@theme/fonts"
import fontSizes from "@theme/fontSizes"
import fontWeights from "@theme/fontWeights"
import forms from "@theme/forms"
import layout from "@theme/layout"
import links from "@theme/links"
import sizes from "@theme/sizes"
import space from "@theme/space"
import styles from "@theme/styles"
import text from "@theme/text"

export default {
  alerts,
  badges,
  breakpoints,
  buttons,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  forms,
  layout,
  links,
  sizes,
  space,
  styles,
  text
}
