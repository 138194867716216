export default {
  avatar: {
    dark: {
      fill: "#fff",
      padding: `4px`,
      width: `32px`,
      height: `32px`,
    },
    light: {
      fill: "#000",
      padding: `4px`,
      width: `32px`,
      height: `32px`,
    },
  },
  backTo: {
    color: "white",
    position: "absolute",
    top: "252px",
    left: "60px",
    fontFamily: "heading",
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0px",
    lineHeight: "45px",
  },
  footer: {
    color: "black",
    fontFamily: "Poppins",
    fontSize: "13px",
    letterSpacing: "0px",
    lineHeight: "29px",
    textDecoration: "none",
    textTransform: "none",
  },
  mainLogo: {
    dark: {
      fill: "#fff",
      height: "44px",
      width: "152px",
    },
    light: {
      fill: "#000",
      height: "44px",
      width: "152px",
    },
  },
  flexLink: {
    alignItems: "center",
    display: "flex",
    paddingLeft: "32px",
  },
  footerLogo: {
    fill: "black",
    ml: "12px",
    width: "94px",
  },
  mainNav: {
    light: {
      color: "#000",
      fontFamily: "heading",
      fontSize: [3],
      fontWeight: "heading",
      letterSpacing: "0px",
      mx: [4],
      textDecoration: "none",
      "&:hover": {
        color: "primary",
      },
    },
    dark: {
      color: "#fff",
      fontFamily: "heading",
      fontSize: [3],
      fontWeight: "heading",
      letterSpacing: "0px",
      mx: [4],
      textDecoration: "none",
      "&:hover": {
        color: "white",
      },
    },
  },
  nav: {
    color: "text",
    fontFamily: "heading",
    fontSize: [3],
    fontWeight: "heading",
    letterSpacing: "0px",
    mx: [4],
    textDecoration: "none",
    "&:hover": {
      color: "text",
    },
  },
  submit: {
    bg: "primary",
    borderRadius: "21px",
    color: "text",
    fontFamily: "heading",
    fontSize: [2],
    fontWeight: "bold",
    minWidth: `auto`,
    px: [4],
    py: "10px",
    textDecoration: "none",
    whiteSpace: `nowrap`,
    "&:hover": {
      color: 'background'
    }
  },
  subnav: {
    bg: "primary",
    borderRadius: "21px",
    color: "text",
    fontFamily: "heading",
    fontWeight: "bold",
    px: [4],
    py: [2],
    textDecoration: "none",
  },
}
