export default {
  input: {
    bg: "white",
    border: `1px solid #D8D8D8`,
    borderRadius: `2rem`,
    color: `text`,
    flex: `1 0 100%`,
    fontFamily: `body`,
    fontSize: `0.8125rem`,
    lineHeight: `1.125rem`,
    outline: "none",
    p: `1.125rem 2rem`,
    "&:active": {
      outline: "none",
    },
    '::placeholder': {
      color: `#808080`,
      fontFamily: `body`,
      fontSize: `0.8125rem`,
      fontWeight: 400
    }
  },
  textarea: {
    width: `100%`,
    height: `326px`,
    border: `1px solid #D8D8D8`,
    borderRadius: `32px`,
    fontFamily: "body",
    p: `1.125rem 2rem`,
    "&:focus": {      
      outline: "none",
    },
  },
  label: {
    alignItems: "center",
    color: `text`,
    flexWrap: 'wrap',
    fontFamily: "body",
    fontSize: "15px",
    mb: '1rem',
    position: 'relative'
  },
}
