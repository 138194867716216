import { darken, lighten } from "@theme-ui/color"

const circle = {
  color: "inherit",
  m: [2],
  position: "relative",
  "&:before": {
    bg: "white",
    borderRadius: "50%",
    content: "' '",
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: "-1",
  },
  "&:disabled": {
    opacity: "0.12",
  },
  "&:active": {
    color: "white",
    fill: "white",
    "&:before": {
      bg: "background",
      borderRadius: "50%",
      content: '" "',
      height: "100%",
      left: 0,
      opacity: "0.24",
      position: "absolute",
      top: 0,
      width: "100%",
      zIndex: "-1",
    },
  },
}

export default {
  event: {
    color: `#0D1537`,
      fontFamily: `heading`,
      fontSize: [`1rem`, `20px`],
      justifyContent: `center`,
      lineHeight: [`2rem`, `3.625rem`],
      width: [`2rem`, `3.625rem`],
      position: 'relative',
      background: `rgba(251, 176, 59, 0.5)`,
      borderRadius: '50%',
      p: 0,
      '&:hover': {
        background: `rgba(251, 176, 59, 1)`,
      }
  },
  next: {
    light: {
      ...circle,
      height: "48px",
      width: "48px",
      "> svg": {
        fill: "black",
        width: "18px",
        height: "18px",
        position: `absolute`
      },
      '&:hover': {
        "> svg": {
          fill: "white",
        },
        "&:before": {
          bg: "black",
        },
      },
      '&:active': {
        "> svg": {
          fill: "white",
        },
        "&:before": {
          bg: "black",
        },
      },
      "&:before": {
        bg: "#E5E4E4",
        borderRadius: "50%",
        content: "' '",
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
      },
      
    }
  },
  prev: {
    light: {
      ...circle,
      height: "48px",
      width: "48px",
      "> svg": {
        fill: "black",
        width: "18px",
        height: "18px",
        position: `absolute`,
      },
      '&:hover': {
        "> svg": {
          fill: "white",
        },
        "&:before": {
          bg: "black",
        },
      },
      '&:active': {
        "> svg": {
          fill: "white",
        },
        "&:before": {
          bg: "black",
        },
      },
      "&:before": {
        bg: "#E5E4E4",
        borderRadius: "50%",
        content: "' '",
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
      },
    }
  },
  google: {
    alignItems: `center`,
    backgroundColor: `white`,
    borderRadius: `40px`,
    borderColor: "#ea4335",
    borderWidth: "1px",
    borderStyle: "solid",
    color: "#000",
    display: "flex",
    flex: `1 0 50%`,
    fontFamily: "Poppins",
    fontWeight: 600,
    height: `58px`,
    justifyContent: `center`,
    letterSpacing: `0px`,
    mb: "1.5rem",
    opacity: 1,
    transition: `background-color 0.3s ease`,
    ">div": {
      ml: "1rem",
    },
    "&:hover": {
      borderColor: darken("primary", 0.15),
      color: "#000",
    },
  },
  submit: {
    backgroundColor: `primary`,
    borderRadius: `40px`,
    color: "#000",
    flex: `1 0 100%`,
    fontFamily: "Poppins",
    fontWeight: 600,
    height: `58px`,
    letterSpacing: `0px`,
    my: "16px",
    opacity: 1,
    transition: `background-color 0.3s ease`,
    "&:hover": {
      backgroundColor: darken("primary", 0.15),
      color: "#fff",
    },
    "&:disabled": {
      backgroundColor: lighten("primary", 0.25),
      color: "muted",
    },
  },
  icon: {
    mx: [2],
    togglePasswordVisibility: {
      height: "auto",
      outline: "none",
      position: "absolute",
      width: "auto",
      right: [2],
      fill: "muted",
      top: "0.5rem",
      "&:focus": { outline: "none" },
      "&:hover": { outline: "none" },
    },
  },
  slide: {
    arrowBtn: {
      light: {
        ...circle,
        height: "52px",
        width: "52px",
        "> svg": {
          fill: "white",
          width: "18px",
          height: "18px",
        },
        "&:before": {
          bg: "#FBB03B !important",
          borderRadius: "50%",
          content: "' '",
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: "-1",
        },
      },
      dark: {
        ...circle,
        height: "52px",
        width: "52px",
        "> svg": {
          fill: "white",
          width: "18px",
          height: "18px",
        },
        "&:before": {
          bg: "#000000 !important",
          borderRadius: "50%",
          content: "' '",
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: "-1",
        },
      },
    },
    next: {
      ...circle,
      bottom: [`-52px`, null, null, `-162px`],
      height: "5.375rem",
      position: "absolute",
      zIndex: 1,
      right: [`2.6875rem`, null, null, 0],
      width: "5.375rem",
    },
    prev: {
      ...circle,
      bottom: [`-52px`, null, null, `-162px`],
      height: "5.375rem",
      position: "absolute",
      zIndex: 1,
      right: [`9.4375rem`, null, null, `6.75rem`],
      width: "5.375rem",
    },
  },
}
