import { bootstrap } from "@theme-ui/presets"

export default {
  // ...bootstrap.colors,
  text: "#000",
  background: "#fff",
  primary: "#FBB03B",
  muted: "#aeaeae",
  secondary: "#6c757d",
  textMuted: "#6c757d",
  layoutColor: '#fff',
  navBorder: `blue`,
  danger: `#ea4335`,
  modes: {
    dark: {
      text: "#000",
      background: "#fff",
      primary: "#0cf",
      layoutColor: '#fff',
      navBorder: 'red'
    },
    light: {
      text: "#fff",
      background: "#000",
      primary: "#07c",
      layoutColor: '#fff'
    },
  },
}
