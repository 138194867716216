import React from "react"
import ThemeProvider from "@providers/ThemeProvider"
import { IdentityContextProvider } from "react-netlify-identity"

import { identityUrl } from "@const"

export default ({ element }) => (
  <IdentityContextProvider url={identityUrl}>
    <ThemeProvider>{element}</ThemeProvider>
  </IdentityContextProvider>
)
