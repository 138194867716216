export default {
  root: {
    backgroundColor: 'layoutColor',
    minWidth: ['35rem', '100%', '100%', '100%', '100rem'],
    position: "relative",
    width: "auto !important",
  },
  h1: {
    fontFamily: "heading",
    letterSpacing: "0px",
    lineHeight: [1.75, 1.5, 1.15],
    fontWeight: "bold",
    fontSize: [6, 7, 9],
    m: 0,
    pt: "1rem",
    pb: "3.25rem",
    width: `100%`,
    "+p": {
      fontFamily: "heading",
      fontSize: "40px",
      letterSpacing: "0px",
      lineHeight: "40px",
      fontWeight: "bold",
      color: "inherit",
      opacity: 0.8,
      pb: "32px",
    },
  },
  h2: {
    fontSize: [8],
    fontWeight: "bold",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  h3: {
    fontFamily: "heading",
    fontSize: [4, 6, 8],
    fontWeight: "bold",
    letterSpacing: "0px",
    lineHeight: [1.75, 1.5, 1.15],
    m: 0,
    pt: "22px",
    "+p": {
      fontFamily: "heading",
      fontSize: "40px",
      letterSpacing: "0px",
      lineHeight: "60px",
      fontWeight: "bold",
      color: "inherit",
      opacity: 0.8,
      pb: "32px",
    },
  },
  h4: {
    color: "inherit",
    display: `flex`,
    flex: "1",
    fontFamily: "heading",
    fontSize: [5, 6, 7],
    fontWeight: "heading",
    lineHeight: "46px",
    m: 0,
    p: 0,
    textTransform: "capitalize",
    "+p": {
      fontFamily: "heading",
      fontSize: "30px",
      letterSpacing: "0px",
      lineHeight: "62px",
      fontWeight: "bold",
      opacity: 0.8,
      pb: "32px",
    },
  },
  h5: {
    color: 'black',
    fontFamily: "heading",
    fontSize: [1],
    fontWeight: "heading",
    lineHeight: "32px",
    mt: 0,
    p: 0,
  },
  h6: {
    color: "black",
    fontFamily: "heading",
    lineHeight: "32px",
    fontWeight: "heading",
    fontSize: [2],
    textTransform: "none",
    m: 0,
    p: 0,
  },
  p: {
    color: "textMuted",
    fontFamily: "body",
    fontSize: [1],
    m: 0,
    p: 0,
  },
}
